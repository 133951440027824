* {
  font-family: "inter", -apple-system, "system-ui", Arial, Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

h2 {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 1.3rem;
}

h3 {
  font-size: 2.1rem;
  font-weight: bold;
  margin-bottom: 0.6rem;
}

p {
  font-size: 2rem;
  font-weight: 300;
}

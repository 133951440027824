.layout {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.page {
  width: 100vw;
  border-bottom: 1px solid black;
}

$image-size: 28px;

.technicalProjects {
  position: relative;
  display: grid;
  grid-template-columns: 1fr minmax(1100px, 1030px) 1fr;
  grid-template-rows: 20vh 1fr 20vh;
  overflow: hidden;
  margin-top: 80px;
  z-index: 5;
}

.projectsContainer {
  display: grid;
  grid-template-rows: 290px 7vh max-content;
  grid-template-columns: 375px minmax(70px, 1fr) 1fr;
  border-radius: 3px;
  z-index: 3;

  grid-row: 2 / 3;
  grid-column: 2 / 3;

  .upperLeft {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
  }

  .subDivider {
    grid-row: 1 / 4;
    grid-column: 2 / 3;
  }

  .upperRight {
    display: grid;
    grid-template-columns: repeat(3, 1fr) min-content;
    grid-template-rows: repeat(auto-fill, 60px);
    margin-top: -10px;
    grid-column: 3 / 4;

    .techItem {
      display: flex;
      align-items: center;
      padding: 5.5px 20px;
      font-weight: 500;
      border-radius: 3px;
      margin: 5px 10px;

      .imageContainer {
        display: flex;
        justify-content: center;

        width: 40px;

        .techImage {
          width: auto;
          // height: 28px;
          height: $image-size;
          margin-right: 10px;
        }

        .tailwind {
          height: $image-size * 1.2;
        }

        .reactQuery {
          height: $image-size * 1.1;
        }

        .ReactNative {
          border-radius: 50%;
        }

        .React {
          border-radius: 4px;
        }

        .SCSS {
          height: $image-size * 1.5;
          margin-left: -3px;
        }

        .Figma {
          height: $image-size * 1.3;
        }

        .Docker {
          height: $image-size * 1.1;
        }

        .Expo {
          height: $image-size * 1.1;
          border-radius: 6px;
        }

        .Firebase {
          height: $image-size * 1.3;
          margin-left: -2px;
        }

        .D3js {
          height: $image-size * 1.2;
        }

        .Git {
          height: $image-size * 1.1;
        }

        .SQL {
          height: $image-size * 1.1;
        }

        .MongoDB {
          height: $image-size * 1.6;
        }
        .TypeScript {
          border-radius: 6px;
          height: $image-size * 1.1;
        }
        .Nodejs {
          height: $image-size * 1.4;
        }
        .Express {
          height: $image-size * 0.6;
        }
        .framer {
          height: $image-size * 0.8;
        }
      }

      .techName {
        width: max-content;
        font-size: 1.8rem;
      }
    }
  }

  .divider {
    grid-column: 1 / -1;
    grid-row: 2 / 3;
    height: 1px;
    box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 1.2px;
    margin-bottom: 65px;
  }
  .downRight {
    display: flex;
    height: auto;
    margin-left: 16px;
    margin-top: 5px;

    .projectHolder {
      width: 50%;
      height: 100%;
    }
  }
}

// Circle bottom
.circleBottom {
  display: block;
  position: absolute;
  top: 6%;
  left: 54%;
  width: 100vw;
  height: 100vw;
  content: "";
  clip-path: circle();
  z-index: 1;
  overflow: hidden;
  background-color: #ffb8b8;
}

.innerCircleBottom {
  display: block;
  position: absolute;
  top: 55%;
  left: 84%;
  width: 10vw;
  height: 10vw;
  content: "";
  clip-path: circle();
  z-index: 2;
  overflow: hidden;
  background-color: white;
}

// Circle top
.technicalProjects::after {
  display: block;
  position: absolute;
  top: 4%;
  left: 5vw;
  width: 36vw;
  height: 36vw;
  content: "";
  clip-path: circle();
  z-index: 1;
  background-color: #fcd0a9;
}

// MEDIA QUERIES
@media screen and (min-width: 3840px) {
  .technicalProjects::after {
    transform: translate(5vw, -5vh);
  }

  .technicalProjects {
    transform: scale(1.4);
  }

  // Circle bottom
  .technicalProjects::before {
    display: block;
    position: absolute;
    top: 50%;
    left: 54%;
    width: 10vw;
    height: 10vw;
  }

  // Circle top
  .technicalProjects::after {
    display: block;
    position: absolute;
    top: 25%;
    left: 25vw;
    width: 20vw;
    height: 20vw;
  }
}

@media screen and (min-width: 1920px) {
  .technicalProjects {
    grid-template-columns: 1fr minmax(1300px, 1030px) 1fr;

    .projectsContainer {
      grid-template-columns: 375px minmax(90px, 100px) 1fr;
    }
  }

  .technicalProjects::after {
    transform: translateX(5vw);
  }
}

@media screen and (max-width: 1380px) {
  .technicalProjects {
    position: relative;
    display: grid;
    grid-template-columns: 50px repeat(12, 1fr) 120px;
    grid-template-rows: 14vh 1fr 5vh;

    .projectsContainer {
      display: grid;
      grid-template-rows: repeat(5, max-content);
      grid-template-columns: 1fr;
      border-radius: 3px;
      padding: 40px 30px;

      grid-row: 2 / 3;
      grid-column: 2 / 14;

      .upperLeft,
      .upperRight,
      .downLeft,
      .downRight,
      .divider {
        grid-row: span 1;
        grid-column: span 4;
      }

      .upperRight {
        margin: 20px 0px;
        .tech {
          display: flex;
          align-items: center;
          padding: 10.5px 0px 20px;
          font-weight: 500;
          border-radius: 3px;
        }
      }

      .downRight {
        margin: 20px 0px 0px 0px;
      }

      .divider {
        margin-top: 30px;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .technicalProjects {
    position: relative;
    display: grid;
    grid-template-columns: 15px repeat(4, 1fr) 15px !important;
    grid-template-rows: 2vh 1fr 2vh;

    .upperRight {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      margin-top: -10px;
      grid-column: 3 / 4;

      .techItem {
        padding-left: 0px;
      }
    }

    .projectTitle {
      span {
        display: none;
      }
    }

    .downRight {
      flex-direction: column;
      height: max-content;

      .projectHolder {
        width: 100%;

        &:last-child {
          margin-top: 10px;
        }
      }

      .holderRight {
        margin: 10px 0px;
      }
    }

    .project {
      height: min-content !important;
      margin: 0 0 10px 0 !important;
    }
  }
}

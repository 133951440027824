.project {
  display: block;
  width: auto;
  height: 145px;
  margin: 0 5px;
  padding: 19px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;

  .projectTitle {
    display: flex;
    justify-content: space-between;

    h3 {
      font-weight: 600;
    }
    span {
      font-weight: 700;
    }
  }

  p {
    font-size: 1.7rem;
    font-weight: 300;
  }
}

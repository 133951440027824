$profile-container-side-padding: 20px;

.about-me {
  display: grid;
  grid-template-columns:
    1fr minmax(min-content, 900px + $profile-container-side-padding * 2)
    1fr;

  grid-template-rows: 8vh 1fr 15vh;
  background-color: #13293d;
  font-weight: 200;

  .profile-container {
    display: flex;
    color: white;
    grid-row: 2 / 3;
    grid-column: 2 / 3;
    padding: 0 $profile-container-side-padding;

    img {
      height: 436px;
      width: 369px;
      object-fit: cover;
      border-radius: 3px;
    }

    .text-container {
      display: flex;
      flex-direction: column;

      flex-shrink: 1;
      flex-grow: 1;

      .interests {
        font-size: 2.4rem;
        font-weight: 300;
        margin-bottom: 30px;
        opacity: 0.8;

        .pipe {
          color: white;
          font-size: 1.8rem;
          font-weight: 600;
        }
      }

      .cta {
        text-transform: uppercase;
        font-size: 2.2rem;
        font-weight: 400;
        margin-bottom: 5px;
      }

      .detail {
        display: flex;
        font-weight: 300;
        font-size: 2rem;
        margin-bottom: 2px;

        .detail-left {
          width: 15%;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .profile-container {
    flex-direction: column;
    align-items: center;

    img {
      height: auto;
      width: 300px !important;
      margin-bottom: 20px;
    }
  }
}

@media screen and (min-width: 1920px) {
  .about-me {
    grid-template-columns: 1fr minmax(1300px, 1030px) 1fr;

    .profile-container {
      grid-template-columns: 375px minmax(90px, 100px) 1fr;
      padding: 0px;
    }
  }
}

@media screen and (min-width: 3840px) {
  .about-me {
    .profile-container {
      transform: scale(1.4);
    }
  }
}

$img-height: 23vw;
$img-width: $img-height * 0.84633;

.boxShadow {
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.3);
}

.heroPageContainer {
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: 8vw repeat(12, 1fr) 10.5vw;
  grid-template-rows: clamp(30vh, 30vh, 50vh) 50vh 1fr;
}

.greetingContainer {
  z-index: 10;
  grid-row: 2/-1;
  grid-column: 2/8;

  .imgWrapper {
    grid-row: 2/-1;
    grid-column: 2/8;
    position: relative;

    img {
      position: absolute;
      z-index: 50;
      height: $img-height;
      width: $img-width;
      object-fit: cover;
      border-radius: 20px;
      top: -5vw;
      right: -50%;
    }
  }
}

.greeting {
  font-size: clamp(22px, 1.3vw, 1.8vw);
  font-weight: 200;
  margin-left: 4px;
  margin-bottom: 0px;
}

.greetingName {
  font-size: clamp(50px, 4vw, 7vw);
  font-weight: 800;
  margin-bottom: 2px;

  img {
    position: absolute;
    display: block;
    z-index: 50;
    height: $img-height;
    width: $img-width;
    object-fit: cover;
    border-radius: 20px;
    top: -4vw;
    right: -20vw;
  }

  &::after {
    content: ".";
  }
}

.greetingParagraph {
  font-size: clamp(23px, 1.7vw, 2.1vw);
  font-weight: 300;
  margin-left: 4px;
  line-height: 1.1;
}

.secondParagraph {
  display: block;
}

.btnContainer {
  grid-template-columns: 2 / 3;
  margin-top: 1.5rem;
  margin-left: 4px;

  button {
    background-color: transparent;
    font-weight: 400;
    padding: 0.5rem 1rem;
    pointer-events: auto;
    color: rgba(0, 0, 0, 1);
    border: 1px solid rgba(0, 0, 0, 1);
    font-size: 2rem;
    border-radius: 3px;
    cursor: pointer;
    opacity: 1;

    &:hover {
      opacity: 0.6 !important;
      transition: 80ms;
    }

    &:last-child {
      margin-left: 1rem;
    }
  }
}

@media screen and (max-width: 1040px) {
  .greetingContainer {
    grid-column: 2 / 14;

    img {
      display: none;
      grid-row: 9;
      margin-top: 0;
      height: $img-height;
      width: $img-width;
      align-self: center;
    }
  }
}

a {
  text-decoration: none;
  color: black;
}

.canvas {
  z-index: 1;
}
